import { i18n } from 'next-i18next.config';

import { LineItem, Upsell } from '@commerce/types/cart';
import { isL360PremiumSKU, isPremiumSKU } from '@components/cart/helpers';
import { ProductType } from '@components/product/enums';
import { isBattery, isJiobit } from '@components/product/helpers';
import { BigcommerceConfig } from '@framework/api';
import { ErrorCode } from '@framework/api/utils/error-code';
import { CART_MAX_ITEM } from '@framework/const';
import { CartItemBody } from '@framework/types/cart';

export function exceedsLimit(
  lineItems: (LineItem | CartItemBody)[],
  bundleQuantity: Record<string, number> = {},
  max = CART_MAX_ITEM
): boolean {
  const total = lineItems.reduce((count, { productId, type, quantity }) => {
    if (type === ProductType.PHYSICAL) {
      return count + (quantity || 1) * (bundleQuantity[productId] || 1);
    }
    return count;
  }, 0);

  return total > max;
}

function getSKU(lineItem: LineItem | CartItemBody | Upsell): string {
  if ((lineItem as LineItem).variant?.sku) {
    return (lineItem as LineItem).variant?.sku;
  }
  return (lineItem as CartItemBody).sku!;
}

export function getSKUs(lineItems: (LineItem | CartItemBody)[]): string[] {
  return lineItems.map(getSKU);
}

export function hasMultiplePremium(
  lineItems: LineItem[],
  addItems: (CartItemBody | Upsell)[],
  config: BigcommerceConfig
): boolean {
  const { locale } = config;
  const totalPremiumAdded = addItems.reduce((acc, { sku }) => acc + (sku && isPremiumSKU(sku, locale) ? 1 : 0), 0);

  // check multiple premium item in the cart (max 1)
  const totalPremiumSKU =
    getSKUs(lineItems).reduce((totalPremium, sku) => totalPremium + (isPremiumSKU(sku, locale) ? 1 : 0), 0) +
    totalPremiumAdded;

  return totalPremiumSKU > 1;
}

export function hasJiobitWithTilePremium(
  lineItems: (LineItem & { brand: string })[],
  addItems: (CartItemBody | Upsell)[],
  config: BigcommerceConfig
): ErrorCode | false {
  const { locale } = config;

  const { addItemIsJiobit, addItemIsTilePremium } = addItems.reduce(
    (acc, addItem) => {
      const addItemSKU = getSKU(addItem);
      if (isPremiumSKU(addItemSKU, locale)) {
        return { ...acc, addItemIsTilePremium: true };
      }
      if (isJiobit((addItem as unknown as CartItemBody).brand || '')) {
        return { ...acc, addItemIsJiobit: true };
      }
      return acc;
    },
    { addItemIsJiobit: false, addItemIsTilePremium: false }
  );

  const { hasJiobitInCart, hasPremiumInCart } = lineItems.reduce(
    (acc, lineItem) => {
      const sku = getSKU(lineItem);
      const premiumInCart = isPremiumSKU(sku, locale);
      const jiobitInCart = isJiobit(lineItem.brand);
      return {
        hasPremiumInCart: acc.hasPremiumInCart || premiumInCart,
        hasJiobitInCart: acc.hasJiobitInCart || jiobitInCart,
      };
    },
    { hasJiobitInCart: false, hasPremiumInCart: false }
  );

  if (hasJiobitInCart && addItemIsTilePremium) {
    return ErrorCode.JIOBIT_TILE_PREMIUM_ADDED;
  }

  if (hasPremiumInCart && addItemIsJiobit) {
    return ErrorCode.TILE_PREMIUM_JIOBIT_ADDED;
  }

  return false;
}

export function isJiobitOrPremiumItem(addItem: CartItemBody | Upsell, { locale }: BigcommerceConfig): boolean {
  const sku = getSKU(addItem);
  return isJiobit((addItem as unknown as CartItemBody).brand || '') || isPremiumSKU(sku, locale);
}

/**
 * to ensure that battery is not added together with other non-battery items
 * @param skus
 * @returns boolean
 */
export function hasBatteryWithOtherItems(skus: string[]): boolean {
  const hasBatteryInCart = skus.some((sku) => isBattery(sku));
  const onlyHasBatteryInCart = skus.every((sku) => isBattery(sku));
  return hasBatteryInCart && !onlyHasBatteryInCart;
}

export function canAddLife360Membership(lineItems: (LineItem | CartItemBody)[]): boolean {
  // TEMP: logic for now is if the cart is completely empty
  return lineItems.length === 0;
}

export function hasLife360Membership(lineItems: (LineItem | CartItemBody)[], locale: string): boolean {
  return lineItems.some((lineItem) => isL360PremiumSKU(getSKU(lineItem), locale));
}

export function hasL360PremiumAndOtherItem(
  lineItems: (LineItem | CartItemBody)[],
  locale = i18n.defaultLocale
): boolean {
  return hasLife360Membership(lineItems, locale) && lineItems.length > 1;
}
